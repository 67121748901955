import React from "react"
import { Link } from "gatsby"
import { Seo } from "../components/Seo/Seo"

export default function ServicesPage() {
  return (
    <React.Fragment>
      <Seo
        title="BIGEYE DIRECT - full service direct mail producer located near Washington DC. Short run laser printing, mail services, secure mail services."
        description="Full service direct mail producer near Washington D.C. Digital laser printing, finishing and mail shop services. Secure mail services."
      />
      <div id="wrapper">
        <div id="topper">
          <div id="logo">
            <Link to="/">
              <img
                src="/wordmark-dark.svg"
                width="234"
                height="108"
                alt="Big Eye Direct"
              />
            </Link>
          </div>
          <div id="contact">
            <h2>703-955-3017</h2>
            <p>
              <a href="mailto:damon@bigeyedirect.com">info@bigeyedirect.com</a>
            </p>
          </div>
        </div>
        <div id="content">
          <div id="nav">
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>

              <li className="active">
                <Link to="/services/">SERVICES</Link>
              </li>
              <li>
                <Link to="/facility/">OUR FACILITY</Link>
              </li>
              <li>
                <Link to="/security/">SECURITY</Link>
              </li>
              <li>
                <Link to="/about/">ABOUT US</Link>
              </li>
              <li className="endNav">
                <Link to="/contact/">CONTACT US</Link>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
          <div id="flasharea">
            {" "}
            <img src="/images/photo_services.jpg" width="926" height="216" />
          </div>
          <div id="textarea">
            <h2>Services</h2>
            <div id="services">
              <p>
                As a full service direct mail producer, the seasoned staff at
                BIGEYE Direct is skilled in taking your project from concept
                &amp; creative to completion.&nbsp; We offer professional
                services in the following areas:{" "}
              </p>
              <ul>
                <h3>Data Processing</h3>
                <li> File conversions</li>
                <li>Filter &amp; data file enhancements</li>
                <li>
                  Custom Merge/Purge
                  <br />
                  (duplicate elimination)
                </li>
                <li> List coding</li>
                <li> Package splits</li>
                <li> Congressional districting</li>
                <li> List Rentals</li>
                <li>NCOA (national change of address)</li>
                <li> CASS/DPV/DSF processing</li>
                <li>
                  {" "}
                  Maximum Postal Discount Analysis:
                  <ul>
                    <li> Presorting</li>
                    <li> BMC/SCF processing for entry point&nbsp;discounts</li>
                    <li>Co-mingled mail processing</li>
                    <li>
                      <p>
                        IMB coding &amp; mail tracking
                        <br />
                        via Track N Trace
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                {" "}
                <h3>Mail Shop Services</h3>
                <li> Folding</li>
                <li>Straight, Stretch, &amp; Master-mailer Inserting</li>
                <li>Inkjet Addressing</li>
                <li>Tabbing/Stamping</li>
                <li>Multi-stamping</li>
                <li>Cutting</li>
                <li>Sorting</li>
                <li>In line metering</li>
                <li>Handwork</li>
              </ul>
              <ul>
                <h3>Laser Personalization</h3>
                <li> Short run digital color printing</li>
                <li> Document Set ups</li>
                <li>Sheet fed laser printing</li>
                <li>Continuous form laser printing</li>
              </ul>

              <div className="clear"></div>
            </div>
          </div>
        </div>
        <div id="footer">
          <a
            rel="noopener noreferrer"
            href="http://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=13860+Redskin+Drive+Herndon,+VA+2017&amp;sll=37.0625,-95.677068&amp;sspn=52.637906,88.330078&amp;ie=UTF8&amp;hq=&amp;hnear=13860+Redskin+Dr,+Herndon,+Fairfax,+Virginia+20171&amp;z=17"
            target="_blank"
          >
            13860 Redskin Drive • Herndon, VA 20171
          </a>
        </div>
        <div id="copyright">
          ©2011 BIGEYE Direct. All rights reserved. Sitemap
        </div>
      </div>
    </React.Fragment>
  )
}
